<template>
  <div>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.per_page],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="items"
      :mobile-breakpoint="0"
      :page="pagination.current_page"
      :server-items-length="pagination.total"
      :class="{ 'content-loading': loading }"
      :loading="loading"
      :item-class="() => 'clickable'"
      disable-sort
      @click:row="tableMixin_onRowClick"
      @update:page="tableMixin_changePage"
    >
      <template v-slot:item.seen="{ item }">
        <SeenDocumentMarker :item="item" @toggled="$emit('toggle:seen', item)" />
      </template>

      <template v-slot:item.no="{ index }">
        {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}.
      </template>

      <template v-slot:item.file_name="{ item }">
        <div class="text-break">{{ item.file_name }}</div>
      </template>

      <template v-slot:item.is_signed="{ item }">
        <v-checkbox
          v-if="item.is_signatures_needed"
          v-model="item.is_signed_by_all_signees"
          class="mt-0 pt-0"
          hide-details
          @click.stop="$emit('toggle:signed', item)"
        />
      </template>

      <template v-slot:item.status="{ item }">
        <div v-if="item.model.status">
          {{ $t(`general.supplier_agreement_statuses.${item.model.status}`) }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="getRowActions(item)"
          :loading="$store.getters.loading[`delete:api/documents/${item.id}`]"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.seen>
            <div class="text-subtitle-2">{{ $t('documents.general.seen') }}</div>
            <SeenDocumentMarker :item="item" @toggled="$emit('toggle:seen', item)" />
          </template>

          <template v-slot:item.is_signed>
            <v-checkbox
              v-if="item.is_signatures_needed"
              v-model="item.is_signed_by_all_signees"
              class="mt-0 pt-0"
              hide-details
              @click.stop="$emit('toggle:signed', item)"
            />
          </template>

          <template v-slot:item.status>
            <div v-if="item.model.status">
              {{ $t(`general.supplier_agreement_statuses.${item.model.status}`) }}
            </div>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
    <input
      :accept="supplierAgreementFileFormats"
      type="file"
      class="d-none"
      ref="documentFileInput"
      @change="onNewDocumentFileSelect"
    />
  </div>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import SeenDocumentMarker from '@/components/SeenDocumentMarker';
import { mapGetters } from 'vuex';

export default {
  name: 'SupplierAgreementTable',

  components: {
    SeenDocumentMarker,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedDocument: {},
    };
  },

  computed: {
    ...mapGetters('supplierAgreements', ['supplierAgreementFileFormats']),

    headers() {
      return [
        {
          text: '',
          value: 'seen',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.no'),
          value: 'no',
          hidden: 'smAndDown',
          hideInExpandedRow: true,
        },
        { text: this.$t('documents.labels.signed'), value: 'is_signed', hidden: 'smAndDown' },
        {
          text: this.$t('documents.labels.file'),
          value: 'file_name',
        },
        {
          text: this.$t('documents.labels.document_date'),
          value: 'document_date',
          hidden: 'xsOnly',
        },
        { text: this.$t('general.supplier'), value: 'model.supplier.name', hidden: 'xsOnly' },
        {
          text: this.$t('documents.labels.valid_from'),
          value: 'model.valid_from',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.date_and_time.valid_until'),
          value: 'model.valid_until',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('documents.labels.document_type'),
          value: 'model.document_description',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.status'),
          value: 'status',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.pending_action_user'),
          value: 'model.pending_action_user.personal_data.full_name',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
        },
      ];
    },
  },

  methods: {
    getRowActions(item) {
      const actions = [];

      if (this.$vuetify.breakpoint.smAndDown) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.preview'),
          icon: 'visibility',
        });
      }

      if (item.is_signed_by_all_signees) {
        return actions;
      }

      actions.push({
        callback: (p) => this.$emit('edit', p),
        label: this.$t('general.controls.edit'),
        icon: 'edit',
      });

      if (!this.$isClient()) {
        actions.push({
          callback: (p) => {
            this.selectedDocument = p;
            this.$refs.documentFileInput.click();
          },
          label: this.$t('documents.general.replace_uploaded_file'),
          loading: this.$store.getters.loading[`post:api/document-sign/${item.id}`],
          icon: 'file_upload',
        });
      }

      if (this.$isAdmin()) {
        actions.push({
          callback: (p) => this.$emit('delete', p),
          label: this.$t('general.controls.delete'),
          icon: 'delete',
        });
      }

      return actions;
    },

    onNewDocumentFileSelect(event) {
      if (!event.target.files[0]) {
        return;
      }
      this.$emit('replace-uploaded-file', {
        supplierAgreement: this.selectedDocument,
        newFile: event.target.files[0],
      });
      this.$refs.documentFileInput.value = '';
    },
  },
};
</script>
