var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.per_page],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.seen",fn:function(ref){
    var item = ref.item;
return [_c('SeenDocumentMarker',{attrs:{"item":item},on:{"toggled":function($event){return _vm.$emit('toggle:seen', item)}}})]}},{key:"item.no",fn:function(ref){
    var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1 + (_vm.pagination.current_page - 1) * _vm.pagination.per_page)+". ")]}},{key:"item.file_name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-break"},[_vm._v(_vm._s(item.file_name))])]}},{key:"item.is_signed",fn:function(ref){
    var item = ref.item;
return [(item.is_signatures_needed)?_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle:signed', item)}},model:{value:(item.is_signed_by_all_signees),callback:function ($$v) {_vm.$set(item, "is_signed_by_all_signees", $$v)},expression:"item.is_signed_by_all_signees"}}):_vm._e()]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.model.status)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(("general.supplier_agreement_statuses." + (item.model.status))))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"loading":_vm.$store.getters.loading[("delete:api/documents/" + (item.id))],"item":item}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.seen",fn:function(){return [_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.$t('documents.general.seen')))]),_c('SeenDocumentMarker',{attrs:{"item":item},on:{"toggled":function($event){return _vm.$emit('toggle:seen', item)}}})]},proxy:true},{key:"item.is_signed",fn:function(){return [(item.is_signatures_needed)?_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle:signed', item)}},model:{value:(item.is_signed_by_all_signees),callback:function ($$v) {_vm.$set(item, "is_signed_by_all_signees", $$v)},expression:"item.is_signed_by_all_signees"}}):_vm._e()]},proxy:true},{key:"item.status",fn:function(){return [(item.model.status)?_c('div',[_vm._v(" "+_vm._s(_vm.$t(("general.supplier_agreement_statuses." + (item.model.status))))+" ")]):_vm._e()]},proxy:true}],null,true)})]}}])}),_c('input',{ref:"documentFileInput",staticClass:"d-none",attrs:{"accept":_vm.supplierAgreementFileFormats,"type":"file"},on:{"change":_vm.onNewDocumentFileSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }