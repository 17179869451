<template>
  <div>
    <div class="d-flex align-center py-2">
      <div v-if="showChips" class="pr-1 d-flex justify-end flex-wrap">
        <v-chip
          v-for="value in submittedValues"
          :key="`${value.key}-${value.value}`"
          style="margin-bottom: 2px"
          close
          @click:close="clearFilter(value.key, value.value)"
          ><template v-if="value.label">{{ value.label }}:</template>
          {{ value.translatedValue || value.value }}</v-chip
        >
      </div>

      <template v-else>
        <div v-if="submittedValues.length" class="text-subtitle-2 pr-1">
          {{ $t('general.selected') }} ({{ submittedValues.length }})
        </div>
      </template>

      <v-tooltip v-if="showResetButton" bottom>
        <template #activator="{ on }">
          <v-btn v-on="on" icon @click.stop="resetFilters">
            <v-icon>autorenew</v-icon>
          </v-btn>
        </template>
        <div>
          {{ $t('general.reset_filters') }}
        </div>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="ma-0" @click.stop="openFilterDialog">
            <v-icon>filter_list</v-icon>
          </v-btn>
        </template>

        <div>
          {{ $t('general.filters.filter') }}
        </div>
      </v-tooltip>
    </div>

    <v-dialog
      :value="$route.query[filterQueryParamName]"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800"
      scrollable
      eager
      @click:outside="closeFilterDialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('general.filters.filters') }}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="button" text @click="closeFilterDialog">
            {{ $t('general.controls.cancel') }}
          </v-btn>
          <v-btn type="button" color="primary" text @click="submitFilters">
            {{ $t('general.filters.filter') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import clone from 'just-clone';

export default {
  name: 'BaseFilterDialogAndChips',

  props: {
    name: {
      // when using multiple BaseFilterDialogAndChips per page
      type: String,
      default: 'is',
    },

    submittedValues: {
      type: Array,
      default: () => [],
    },

    hideChips: {
      type: Boolean,
      default: false,
    },

    showResetButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filterQueryParamName() {
      return `${this.name}-filter-open`;
    },

    showChips() {
      return !this.hideChips && this.$vuetify.breakpoint.mdAndUp;
    },
  },

  methods: {
    openFilterDialog() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.filterQueryParamName]: true,
        },
      });
      this.$emit('open');
    },

    closeFilterDialog() {
      const query = clone(this.$route.query);
      delete query[this.filterQueryParamName];
      this.$router.push({
        ...this.$route,
        query,
      });
    },

    clearFilter(key, value) {
      this.$emit('clear:filter', key, value);
    },

    resetFilters() {
      this.$emit('reset');
    },

    submitFilters() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped></style>
