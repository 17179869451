<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchSupplierAgreements(supplierAgreementFilterParams)">
        {{ $t('general.documents') }}
      </v-tab>

      <v-tab-item>
        <div class="d-flex align-center justify-end px-4">
          <SupplierAgreementFilter
            :filter-params="supplierAgreementFilterParams"
            @change="$router.push({ name: 'supplierAgreements', query: $event })"
            @reset="resetSupplierAgreementFilters"
          />

          <v-btn
            :loading="downloadingSupplierAgreements"
            :disabled="downloadingSupplierAgreements"
            icon
            @click="downloadSupplierAgreements"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </div>

        <SupplierAgreementTable
          :items="supplierAgreements"
          :loading="loadingSupplierAgreements"
          :pagination="supplierAgreementPagination"
          @click:row="openDocument"
          @delete="deleteSupplierAgreement"
          @edit="$router.push({ name: 'editSupplierAgreement', params: { id: $event.id } })"
          @replace-uploaded-file="uploadNewSupplierAgreementFile"
          @toggle:seen="toggleSeenSupplierAgreement"
          @toggle:signed="toggleSupplierAgreementSignature"
          @update:page="onPageChange"
        />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      v-if="!$isClient()"
      icon="file_upload"
      @click="$router.push({ name: 'createSupplierAgreement' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import SupplierAgreementTable from '@/components/tables/SupplierAgreementTable';
import SupplierAgreementFilter from '@/components/filters/SupplierAgreementFilter';
import { getDefaultSupplierAgreementFilterParams } from '@/store/modules/supplier-agreements-module';
import { getSanitizedFilterParams } from '@/util/filter-params';
import { openFile } from '@/util/event-bus';

export default {
  name: 'SupplierAgreements',

  components: {
    BasePrimaryActionButton,
    SupplierAgreementTable,
    SupplierAgreementFilter,
  },

  computed: {
    ...mapState('supplierAgreements', [
      'supplierAgreements',
      'supplierAgreementPagination',
      'supplierAgreementFilterParams',
      'downloadingSupplierAgreements',
      'loadingSupplierAgreements',
    ]),
  },

  created() {
    this.fetchSupplierAgreements(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.supplierAgreementFilterParams) !== JSON.stringify(params) &&
      to.name === 'supplierAgreements'
    ) {
      this.fetchSupplierAgreements(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('supplierAgreements', [
      'fetchSupplierAgreements',
      'deleteSupplierAgreement',
      'downloadSupplierAgreements',
      'toggleSeenSupplierAgreement',
      'toggleSupplierAgreementSignature',
      'uploadNewSupplierAgreementFile',
    ]),

    onPageChange(page) {
      const query = { ...this.supplierAgreementFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'supplierAgreements', query });
    },

    resetSupplierAgreementFilters() {
      const defaultFilters = getDefaultSupplierAgreementFilterParams();
      if (JSON.stringify(defaultFilters) === JSON.stringify(this.supplierAgreementFilterParams)) {
        return;
      }
      this.$router.push({ name: 'supplierAgreements', query: defaultFilters });
    },

    openDocument(attachment) {
      openFile({
        name: attachment.file_name,
        url: `api/document-download/${attachment.id}`,
      });
    },
  },
};
</script>
